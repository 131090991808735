import { Chat } from '@/features/account/components/Chat'
import { type CMSPageDataWithSpecificLayout } from '@/features/cms/components/CMS/types'
import { GapManager } from '@/features/cms/components/CMS/components/GapManager'
import { getCMSWrapperProps } from '@/features/cms/components/CMS/utils/getCMSWrapperProps'
import { PageLayoutCMSSection } from '@/features/cms/components/CMS/PageLayoutCMSSection'
import { PageTitle } from '@/features/cms/components/CMS/components/PageTitle'

/**
 * This component corresponds to the `page_layout` content type in the CMS.
 *
 * - **Staging** - https://cms-staging.shipt.com/content-type/pool/page_layout
 * - **Production** - https://cms.shipt.com/content-type/pool/page_layout
 */
export const PageLayout = ({
  layoutData: { id, content_type_id, data },
}: {
  layoutData: CMSPageDataWithSpecificLayout<'page_layout'>['content']
}) => (
  <div {...getCMSWrapperProps({ content_type_id, id })}>
    {data?.announcement && <PageLayoutCMSSection section={data.announcement} />}
    {data?.header && <PageLayoutCMSSection section={data.header} />}
    {data?.page_title && (
      <GapManager
        verticalSpacing={
          data.page_title.data.hidden ? 'none' : data.vertical_spacing
        }
        mobileVerticalSpacing={
          data.page_title.data.hidden
            ? 'none'
            : data.mobile_web_vertical_spacing
        }
      >
        <PageTitle {...data.page_title} />
      </GapManager>
    )}
    {data?.body && (
      <GapManager
        verticalSpacing={data.vertical_spacing}
        mobileVerticalSpacing={data.mobile_web_vertical_spacing}
      >
        <PageLayoutCMSSection section={data.body} />
      </GapManager>
    )}
    {data?.footer && <PageLayoutCMSSection section={data.footer} />}
    <Chat />
  </div>
)
