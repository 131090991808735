import styled from 'styled-components'
import { Column } from '@shipt/design-system-layouts'
import { spacing } from '@/features/shared/theme/tokens'
import { type Size, screenSizes } from '@shipt/design-system-themes'

export const GapManager = styled(Column).attrs({ align: 'center' })<{
  verticalSpacing?: Size | 'none'
  mobileVerticalSpacing?: Size | 'none'
}>`
  --vertical-spacing: ${({ verticalSpacing }) =>
    verticalSpacing === 'none' ? 0 : spacing(verticalSpacing ?? 120)};

  --mobile-vertical-spacing: ${({ mobileVerticalSpacing }) =>
    mobileVerticalSpacing === 'none'
      ? 0
      : spacing(mobileVerticalSpacing ?? 120)};

  ${({ mobileVerticalSpacing }) =>
    mobileVerticalSpacing &&
    `
  @media ${screenSizes.max_mobile} {
    --cms-gap:  var(--mobile-vertical-spacing)    
  }`}

  --cms-gap: var(--vertical-spacing);

  gap: var(--cms-gap);
  margin: calc(var(--cms-gap) / 2) 0;

  & > .no-margin {
    margin: calc(var(--cms-gap) / -2);
  }

  & > .with-background {
    margin: calc(var(--cms-gap) / -2);
    padding: calc(var(--cms-gap) / 4) 0;

    @media ${screenSizes.tablet} {
      padding: calc(var(--cms-gap) / 2);
    }
  }
`
